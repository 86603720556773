import React from 'react';
import './css/projects.css';
import ProjectDisplay from '../components/projectDisplay'
import Mihtavomat from './img/mihtavomat.jpg';
import Yudnungames from './img/yudnungames.jpg';
import Smarthome from './img/smarthome.jpg';

export default function projects() {
  return (
    <>
        <div id="projects" className="projectsSection container">
            <h1 className="projectsTitle mb-5">Projects:</h1>
            <div className="projectDisclaimerWrapper container">
              {/* <p className="projectDesc mb-3">Here is a list of a few of my favorite projects.</p> */}
              {/* <p className="projectDesc mb-5">More fun and exciting projects are soon to come!</p> */}
            </div>
            <div className="projectsWrapper">
                <ProjectDisplay projectImg={Mihtavomat} projectName={"Mihtavomat"} projectDesc={"The Mihtavomat sends a letter of complete nonsense as a generated PDF file to a selected email address."} rtl='false'/>
                <ProjectDisplay projectImg={Yudnungames} projectName={"The Yudnun games"} projectDesc={'Social games management system based on the popular Netflix show "Squid Game".'}/>
                <ProjectDisplay projectImg={Smarthome} projectName={"Smart Home"} projectDesc={"Smart home administration app that is connected to a custom electronics circuit, powered by a Raspberry Pi and an Arduino."} rtl='false' vertical='true'/>
            </div>
        </div>
    </>
  )
}
