import React from 'react';
import './projectDisplay.css';

export default function ProjectDisplay({projectImg, projectName, projectDesc, rtl, vertical}) {
  
  if(vertical == 'true'){
    var imgClass = 'projectImgVertical';
  }else {
    var imgClass = 'projectImg';
  }
  


  if(rtl == 'false'){
    return (
      <>
          <div className="projectDisplayWrapper d-lg-flex flex-lg-row-reverse container justify-content-center">
              <div className="projectTextWrapper">
                <p className="projectName">{projectName}</p>
                <p className="projectDesc mb-3">{projectDesc}</p>
              </div>

              <div className="projectImgWrapper">
                <img src={projectImg} className={imgClass} alt="Project Image" />
              </div>
          </div>
      </>
    );
  }else {
    return (
      <div className="projectDisplayWrapper d-lg-flex container justify-content-center">
            <div className="projectTextWrapper w-100">
              <p className="projectName">{projectName}</p>
              <p className="projectDesc mb-3">{projectDesc}</p>
            </div>

            <div className="projectImgWrapper w-100">
              <img src={projectImg} className="projectImg" alt="Project Image" />
            </div>
        </div>
    );
  }
}