import React from 'react';
import './css/MainTitle.css';
import ProgrammerSVG from './img/programmer.svg';
import Typical from 'react-typical';

export default function MainTitle() {
  return (
    <>
      <div id="mainTitle" className="mainTitle">
        <div className="nameImgWrapper d-lg-flex justify-content-center">
          <div className="nameWrapper text-left p-lg-4">
              <p className='myName'>Kfir Levi</p>

              <p className='myTitle'>
                <Typical
                    steps={['<>Full Stack developer</>']}
                    wrapper="p"
                />
              </p>
          </div>

          <img src={ProgrammerSVG} className="svgGraphic p-lg-4" alt="Programmer" />
        </div>

        <div className="waveWrapper">
          <div className="wave">
            <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                <path d="M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z" className="shape-fill"></path>
            </svg>
          </div>
        </div>
        
      </div>
    </>
  )
}
