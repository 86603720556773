import React from 'react';
import './contactPlatform.css';

export default function contactPlatform({href, platformName, platformImg}) {
  return (
    <>
        <a className="platformLink d-flex justify-content-center" href={href} target="_blank">
            <div className="platformImgWrapper">
                <img className="platformImg" src={platformImg} alt={platformName} />
            </div>

            <div className="platformNameWrapper d-none d-lg-block">
                <p className="platformName">{platformName}</p>
            </div>
        </a>
    </>
  )
}
