import 'bootstrap/dist/css/bootstrap.css';
import { Routes, Route } from "react-router-dom";
import './pages/pageComponents/css/General.css';
import Homepage from './pages/homepage';
import NotFound from './pages/NotFound';

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  );
}

export default App;
