import React from 'react';
import './css/contactMe.css'
import ContactPlatform from '../components/contactPlatform';
import Mail from './img/mail.svg';
import Github from './img/github.svg';
import Linkedin from './img/linkedin.svg';

export default function ContactMe() {
  return (
    <>
        {/* <div className="splitWrapper">
            <div className="split2">
                <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                    <rect x="1200" height="3.6"></rect>
                    <rect height="3.6"></rect>
                    <path d="M0,0V3.6H580.08c11,0,19.92,5.09,19.92,13.2,0-8.14,8.88-13.2,19.92-13.2H1200V0Z" className="shape-fill"></path>
                </svg>
            </div>
        </div> */}

        <div id='contactme' className="contactDetailsWrapper container">
            <div className="contactMe mb-3">
                <p className="contactMeHeader">Let's get in touch</p>
            </div>

            <div className="contactPlatformsWrapper">
                <div className="d-flex justify-content-center">
                    <ContactPlatform href="mailto: kfir@kfirlevi.dev" platformName="kfir@kfirlevi.dev" platformImg={Mail} />
                    <ContactPlatform href="https://github.com/Kfir20" platformName="Github" platformImg={Github} />
                </div>

                <div className="d-flex justify-content-center mt-3">
                    <ContactPlatform href="https://www.linkedin.com/in/kfirlevi/" platformName="Linkedin" platformImg={Linkedin} />
                </div>
            </div>
        </div> 
    </>
  )
}
