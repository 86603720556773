import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Flag from '../components/Flag';
import './css/navbar.css';
import logo from './img/logo.svg';

export default function MyNavbar() {

  useEffect(() => {
    // Hide the navbar when scrolling

    const navBar = document.getElementById('navbarWrapper');
    let lastScrollY = window.scrollY;
    const navCollapse = document.getElementById('responsive-navbar-nav');
    
    window.addEventListener("scroll", () => {

        if(lastScrollY < window.scrollY && !navCollapse.classList.contains('show')){
          navBar.classList.add("navbarClosed");
        }else {
          navBar.classList.remove("navbarClosed");
        }

        lastScrollY = window.scrollY;
    });

  });

  function closeNavbarOnClick() {
    const navBar = document.getElementById('navbarWrapper');
    navBar.classList.add("navbarClosed");
  }

  return (
    <header>
      <Navbar id='navbarWrapper' className='myNavbar' collapseOnSelect expand="lg" variant="dark">
        {/* <Container> */}
          <Navbar.Brand className='homeLink' href="#">
            <img className='logo d-inline-block align-top' src={logo} alt="logo"/>
          </Navbar.Brand>

          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto">
              {/* This one remains empty so my links will go on the right side of the navbar */}
            </Nav>
            <Nav className='navMobileBG'>
              <Nav.Link onClick={closeNavbarOnClick} href="#aboutme"><span className="navNum">01. </span>About me</Nav.Link>
              <Nav.Link onClick={closeNavbarOnClick} href="#projects"><span className="navNum">02. </span>Projects</Nav.Link>
              <Nav.Link onClick={closeNavbarOnClick} href="#contactme"><span className="navNum">03. </span>Contact me</Nav.Link>

              <button onClick={closeNavbarOnClick} className='resumeButton d-none'>Resume</button>
              
              <Flag onClick={closeNavbarOnClick} className='languagePicker' myFlag="US"></Flag>
            </Nav>
          </Navbar.Collapse>
        {/* </Container> */}
      </Navbar>
    </header>
  )
}
