import React from 'react';
import MyNavbar from './pageComponents/myNavbar';
import MainTitle from './pageComponents/MainTitle';
import Aboutme from './pageComponents/Aboutme';
import Projects from './pageComponents/projects';
// import ContactForm from './pageComponents/contactForm';
import ContactMe from './pageComponents/contactMe';
import Footer from './pageComponents/footer';

export default function homepage() {
  return (
    <>
      <MyNavbar />
      <MainTitle />
      <Aboutme />
      <Projects />
      {/* <ContactForm /> */}
      <ContactMe />
      <Footer />
    </>
  )
}
