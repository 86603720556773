import React from "react";
import "./css/Footer.css";
import "./css/Responsive.css";

export default function footer() {
  return (
    <>
      <div className="splitWrapper">
        <div className="split">
          <svg
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1200 120"
            preserveAspectRatio="none"
          >
            <rect x="1200" height="3.6"></rect>
            <rect height="3.6"></rect>
            <path
              d="M0,0V3.6H580.08c11,0,19.92,5.09,19.92,13.2,0-8.14,8.88-13.2,19.92-13.2H1200V0Z"
              className="shape-fill"
            ></path>
          </svg>
        </div>
      </div>

      <div className="footerWrapper container text-center pt-3 pb-3">
        <p className="footerText">
          Designed and developed by Kfir Levi {new Date().getFullYear()}
        </p>
      </div>
    </>
  );
}
